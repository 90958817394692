import { titleCase as convertToTitleCase } from 'title-case';

/**
 *
 * @date 2022-04-19
 * @param {string} title - A string param
 * @returns {string}
 * @description param title is converted with title case and since letters after : and - need to capitalize, title is split and convertToTitle case
 * @example {}
 *    titleCase("A lemon is good"):
 *        return "A Lemon is good";
 *
 *    titleCase("A Vary is happy: the filming"):
 *        return ("A Vary is Happy: The filming");
 */
export const titleCase = (title) => {
  if (!title || typeof title !== 'string') {
    return '';
  }
  return title
    .toLowerCase()
    .split(': ')
    .map((item) => convertToTitleCase(item))
    .join(': ')
    .split(' - ')
    .map((item) => convertToTitleCase(item))
    .join(' - ');
};


export const truncateText = (text, maxLength) => {
  if (!text) return "";
  return text.length > maxLength ? text.slice(0, maxLength) + "..." : text;
};

